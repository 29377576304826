import { Listbox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Checkbox, message, Modal, Switch } from "antd";
import RentalCarDatesRangeInput from "components/HeroSearchForm/RentalCarDatesRangeInput";
import { useBookingData } from "contexts/BookingContext";
import { useDateContext } from "contexts/DateContext";
import { UserContext } from "contexts/UserContext";
import useWindowSize from "hooks/useWindowResize";
import moment, { Moment } from "moment";
import { FC, useContext, useEffect, useState } from "react";
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay,
} from "react-dates";
import { useHistory, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import axios from "../../services/api";
import LikeSaveBtns from "./LikeSaveBtns";
import MobileFooterSticky from "./MobileFooterSticky";
import ModalPhotos from "./ModalPhotos";

export interface ListingCarDetailPageProps {
  className?: string;
}

const ListingCarDetailPage: FC<ListingCarDetailPageProps> = ({
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  let { id }: any = useParams();
  const history = useHistory();
  const user: any = useContext(UserContext);
  const [open, setOpen] = useState(false);
  var {
    dateRangeValue,
    setDateRangeValue,
    timeRangeValue,
    setTimeRangeValue,
  }: any = useDateContext();
  const { booking, setBooking }: any = useBookingData();
  const [VehicleStatus, setVehicleStatus] = useState<any>(false);
  const [slots, setSlots] = useState<any>([]);
  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };
  function formatDateToISOString(date: Date) {
    // Get the year, month, day, hours, and minutes
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Return the formatted date string
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  const parseTime = (
    timeString: string
  ): { hours: number; minutes: number } => {
    const [time, meridian] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);
    const adjustedHours =
      meridian === "PM" && hours !== 12
        ? hours + 12
        : meridian === "AM" && hours === 12
          ? 0
          : hours;
    return { hours: adjustedHours, minutes };
  };
  useEffect(() => {
    const start = new Date(dateRangeValue.startDate._d);
    const end = new Date(dateRangeValue.endDate._d);

    // Parse timeRangeValue's startTime and endTime
    const { hours: startHours, minutes: startMinutes } = parseTime(
      timeRangeValue.startTime
    );
    const { hours: endHours, minutes: endMinutes } = parseTime(
      timeRangeValue.endTime
    );

    // Set start and end dates with values from timeRangeValue
    const startDate = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      startHours,
      startMinutes,
      0
    );
    const endDate = new Date(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      endHours,
      endMinutes,
      0
    );

    // Add buffer to start and end dates
    const startDateWithBuffer = new Date(
      startDate.getTime() - 2 * 60 * 60 * 1000
    ); // 2 hours earlier
    const endDateWithBuffer = new Date(endDate.getTime() + 2 * 60 * 60 * 1000); // 2 hours later

    // Fetch vehicle status
    axios
      .get(
        `/vehicle-status/${id}?startDate=${formatDateToISOString(
          startDateWithBuffer
        )}&endDate=${formatDateToISOString(endDateWithBuffer)}`
      )
      .then((res) => {
        setVehicleStatus(res.data.status);
        setSlots(res.data.bookingString);
      });
  }, [id, dateRangeValue, timeRangeValue, setVehicleStatus]);
  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const [vehicleData, setVehicleData] = useState<any>({
    vehicleName: "...",
    images: [],
    included: [],
    price: 0,
    locationCoordinates: "",
    vehicleLocation: "Ameerpet, Hyderabad",
    amenities: [],
    pricePerHour: 0,
    dailyPrice: 0,
    weeklyPrice: 0,
    monthlyPrice: 0,
  });

  const getDate = (startDate: Moment, startTime: string) => {
    var time = startTime.split(" ");
    var hour = time[0].split(":")[0];
    var min = time[0].split(":")[1];
    var ampm = time[1];
    if (ampm == "PM" && parseInt(hour) != 12) {
      hour = (parseInt(hour) + 12).toString();
    }
    var date = startDate
      ?.toDate()
      .setHours(parseInt(hour), parseInt(min), 0, 0);
    return date;
  };

  const [rentDuration, setRentDuration] = useState(0);

  const [rentPrice, setRentPrice] = useState(0);

  const [originalPrice, setOriginalPrice] = useState(0);

  const [addonsPrice, setaddonsPrice] = useState(0);

  const [tcAgree, setTcAgree] = useState(false);

  const calculateRent = () => {
    if (dateRangeValue.startDate == null || dateRangeValue.endDate == null) return;

    const startDateTime = getDate(dateRangeValue.startDate, timeRangeValue.startTime);
    const endDateTime = getDate(dateRangeValue.endDate, timeRangeValue.endTime);

    let hours = Math.abs(endDateTime - startDateTime) / 36e5;

    const minDuration = vehicleData.minDuration;

    // Ensure hours meet the minimum duration
    if (hours < minDuration) {
        message.info({
            content: `Rental duration is less than the minimum. Rent will be calculated for ${minDuration} hours.`,
            key: "minDuration",
        });
        hours = minDuration;
    }

    setRentDuration(hours);

    const pricePerHour = vehicleData.pricePerHour;
    const dailyPrice = vehicleData.dailyPrice || vehicleData.price * 24;
    const weeklyPrice = vehicleData.weeklyPrice;
    const monthlyPrice = vehicleData.monthlyPrice;

    let totalPrice = 0;
    const baseHourlyPrice = hours * pricePerHour;

    // Convert hours into months, weeks, days, and remaining hours
    let days = Math.floor(hours / 24);
    hours = hours % 24;
    const months = Math.floor(days / 30);
    days = days % 30;
    let weeks = Math.floor(days / 7);
    days = days % 7;

    // Calculate the total price
    totalPrice = Math.ceil(
        months * monthlyPrice + weeks * weeklyPrice + days * dailyPrice + hours * pricePerHour
    );

    setRentPrice(totalPrice);
    setOriginalPrice(baseHourlyPrice);
    setaddonsPrice(0);
};

  const caleculateOutstaionRent = () => {
    if (!dateRangeValue.startDate || !dateRangeValue.endDate) return;

    const startDateTime = getDate(dateRangeValue.startDate, timeRangeValue.startTime);
    const endDateTime = getDate(dateRangeValue.endDate, timeRangeValue.endTime);
    const hours = Math.abs(endDateTime - startDateTime) / 36e5;
    const days = Math.floor(hours / 24) + (hours % 24 > 0 ? 1 : 0);

    const rentDays = Math.max(days, Math.ceil(vehicleData.minDuration / 24));
    const rentPrice = rentDays * vehicleData.outStationPrice * 24;

    setRentDuration(hours);
    setRentPrice(rentPrice);
    setOriginalPrice(rentPrice);
    setaddonsPrice(0);
  };
  useEffect(() => {
    booking.outStation ? caleculateOutstaionRent() : calculateRent();

  }, [dateRangeValue, timeRangeValue, vehicleData, booking.outStation]);

  const getVehicleData = async () => {
    axios
      .get("/vehicle/" + id)
      .then((response) => {
        const rawData = response.data;
        console.log(response.data);

        setVehicleData({
          id: rawData.data._id,
          vehicleName: rawData.data.name,
          type: rawData.data.type,
          gears: rawData.data.gears,
          description: rawData.data.description,
          fuelCapacity: rawData.data.fuelCapacity,
          included: rawData.data.included,
          fuel: rawData.data.fuel,
          price: rawData.data.price,
          outStationPrice: rawData.data.outStationPrice,
          KMLimitPerHourOutStation: rawData.data.KMLimitPerHourOutStation,
          KMLimitPerHour: rawData.data.KMLimitPerHour,
          extraKMCharges: rawData.data.extraKMCharges,
          locationCoordinates: rawData.data.locationCoordinates,
          images: [rawData.data.image, ...rawData.data.images],
          location: rawData.data.location,
          amenities: rawData.data.features,
          minDuration: rawData.data.minDuration,
          pricePerHour: rawData.data.pricePerHour,
          dailyPrice: rawData.data.dailyPrice,
          weeklyPrice: rawData.data.weeklyPrice,
          monthlyPrice: rawData.data.monthlyPrice,
        });
        if (
          rawData.data.outStationPrice == 0 ||
          rawData.data.outStationPrice == null ||
          rawData.data.outStationPrice == undefined
        ) {
          setBooking({ ...booking, outStation: false });
        }
      })
      .catch((error) => {
        history.push("/404");
        console.log(error);
      });
  };
  useEffect(() => {
    getVehicleData();
    booking.outStation ? caleculateOutstaionRent() : calculateRent();
  }, []);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-4 pb-4 sm:hidden">
        <div className="flex justify-between items-center">
          <div></div>
          <LikeSaveBtns />
        </div>
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {vehicleData.vehicleName}
        </h2>
        <div className="flex items-center">
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Category:
            <span className="text-neutral-900 dark:text-neutral-200 font-medium capitalize">
              {" "}
              {vehicleData.type}
            </span>
          </span>
        </div>
        {vehicleData?.location?.includes("Adibatla") ? (
          <div className="flex items-center space-x-4">
            <a href="https://maps.app.goo.gl/2SjdRAaC8r2SYzGs9" target="_blank">
              <i className="las la-map-marker-alt"></i>
              <span className="ml-1">A {vehicleData?.location}</span>
            </a>
          </div>
        ) : (
          <div className="flex items-center space-x-4">
            <a
              href="https://www.google.com/maps/search/?api=1&query=17.324760809168968,78.52926361462093"
              target="_blank"
            >
              <i className="las la-map-marker-alt"></i>
              <span className="ml-1"> {vehicleData?.location}</span>
            </a>
          </div>
        )}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
        <div className="flex items-center justify-between xl:justify-start space-x-4 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-cogs text-2xl"></i>
            <span className=""> {vehicleData.gears} gears</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-charging-station text-2xl"></i>
            <span className="">Fuel {vehicleData.fuel}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1_mod = () => {
    return (
      <div className="listingSection__wrap !space-y-4 hidden sm:block">
        <div className="flex justify-between items-center">
          <div></div>
          <LikeSaveBtns />
        </div>
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {vehicleData.vehicleName}
        </h2>
        <div className="flex items-center">
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Category:
            <span className="text-neutral-900 dark:text-neutral-200 font-medium capitalize">
              {" "}
              {vehicleData.type}
            </span>
          </span>
        </div>
        <div className="flex items-center space-x-4">
          <a href="https://www.google.com/maps/search/?api=1&query=17.324760809168968,78.52926361462093">
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {vehicleData.location}</span>
          </a>
        </div>

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-cogs text-2xl"></i>
            <span className=""> {vehicleData.gears} gears</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-charging-station text-2xl"></i>
            <span className="">Fuel {vehicleData.fuel}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSectionTienIch = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">
            Vehicle parameters & utilities{" "}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Questions are at the heart of making things great.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
          {vehicleData.amenities
            .filter((e: string) => e != "")
            .map((item: any, index: number) => (
              <div key={index} className="flex items-center space-x-4 ">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Vehicle descriptions</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <p>{vehicleData.description}</p>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Include </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Included in the price
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {vehicleData?.included
            ?.filter((e: string) => e != "")
            ?.map((item: any) => (
              <div key={item} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap lg:hidden">
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
            <DayPickerRangeController
              minimumNights={0}
              startDate={dateRangeValue.startDate}
              endDate={dateRangeValue.endDate}
              onDatesChange={(date) => setDateRangeValue(date)}
              focusedInput={focusedInputSectionCheckDate}
              onFocusChange={(focusedInput) =>
                setFocusedInputSectionCheckDate(focusedInput || "startDate")
              }
              initialVisibleMonth={null}
              numberOfMonths={windowSize.width < 1280 ? 1 : 2}
              daySize={getDaySize()}
              hideKeyboardShortcutsPanel
              isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
            />
          </div>
        </div>
        <div className="flex space-x-8">
          <div className="w-1/2 space-y-2">
            <label className="font-medium" htmlFor="startTime">
              Pick up time:
            </label>
            <TimeSelect
              value={timeRangeValue.startTime}
              onChange={(time: string) =>
                setTimeRangeValue({ ...timeRangeValue, startTime: time })
              }
            />
          </div>
          <div className="w-1/2 space-y-2">
            <label className="font-medium" htmlFor="endTime">
              Drop off time:
            </label>
            <TimeSelect
              value={timeRangeValue.endTime}
              onChange={(time: string) =>
                setTimeRangeValue({ ...timeRangeValue, endTime: time })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap lg:!mb-4">
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Please request Cancellation 24 hrs prior the booking time.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            We StartRides Team are greatful to have you as our privileged
            customer and we would recommend to drive safe and avoid rash
            driving.
          </span>
        </div>
      </div>
    );
  };

  const renderSidebarPrice = () => {
    return (
      <div
        id="booknow_container"
        className="listingSectionSidebar__wrap shadow-xl"
      >
        <div
          className={
            vehicleData.outStationPrice == undefined ||
              vehicleData.outStationPrice == 0
              ? "hidden"
              : ""
          }
        >
          <div className={" flex justify-between items-center"}>
            <h2 className="text-2xl font-semibold">Price</h2>
            <div className="flex items-center space-x-2">
              <span className="text-sm font-semibold">Outstation</span>
              <Switch
                checked={booking.outStation}
                onChange={() => {
                  setBooking({ ...booking, outStation: !booking.outStation });
                }}
                className={`${booking.outStation
                  ? "bg-primary-500"
                  : "bg-neutral-200 dark:bg-neutral-700"
                  }
                                    relative inline-flex items-center rounded-full w-11`}
              />
            </div>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <span
              className={
                "text-3xl font-semibold " +
                (booking.outStation ? "text-gray-500" : "")
              }
            >
              ₹ {vehicleData.pricePerHour * 24}
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                / day
              </span>
            </span>

            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              In Station
            </span>
          </div>
          {(vehicleData.outStationPrice ||
            vehicleData.outStationPrice != 0) && (
              <div className="flex flex-col items-end">
                <div
                  className={
                    "text-3xl font-semibold " +
                    (!booking.outStation ? "text-gray-500" : "")
                  }
                >
                  <span className="text-3xl font-semibold">
                    ₹ {vehicleData.outStationPrice * 24}
                    <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                      / day
                    </span>
                  </span>
                </div>
                <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                  Out Station
                </span>
              </div>
            )}
        </div>

        <form className="flex border  border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <RentalCarDatesRangeInput
            defaultDateValue={dateRangeValue}
            defaultTimeValue={timeRangeValue}
            numberOfMonths={2}
            fieldClassName="p-3"
            wrapFieldClassName="flex flex-col w-full flex-shrink-0 relative divide-y divide-neutral-200 dark:divide-neutral-700"
            className="RentalCarDetailPageDatesRangeInput flex-1"
            onChange={(data) => {
              setDateRangeValue(data.stateDate);
              setTimeRangeValue(data.stateTimeRage);
            }}
            anchorDirection={"right"}
            hasButtonSubmit={false}
          />
        </form>
        <div className="flex flex-col space-y-4 ">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              ₹{" "}
              {(booking.outStation
                ? vehicleData.outStationPrice
                : vehicleData.price) * 24}{" "}
              x {Math.ceil(rentDuration / 24)} day(s)
            </span>
            <span> {rentPrice === originalPrice ? `₹ ${rentPrice}` : <span><s className="mr-2">₹{originalPrice}</s>₹{rentPrice}</span>} /-</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Addons Charges </span>
            <span>₹ {addonsPrice} /-</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Other Charges </span>
            <span>₹ 0 /-</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>KM Limit</span>
            <span>
              {Math.round(
                ((booking.outStation
                  ? vehicleData.KMLimitPerHourOutStation
                  : vehicleData.KMLimitPerHour) || 5) *
                (Math.ceil(rentDuration / 24) * 24)
              ).toFixed(0)}{" "}
              km(s)
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Extra Charge Per KM</span>
            <span>₹ {vehicleData.extraKMCharges || 30} /-</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>₹ {rentPrice + addonsPrice}</span>
          </div>
        </div>
        <Modal
          className="dark:booking-confirmation"
          open={open}
          onCancel={() => setOpen(false)}
          footer={null}
          title={`Confirmation ${booking.outStation ? "Out Station" : "In Station"
            } booking`}
        >
          <div className="flex flex-col text-neutral-900 dark:text-neutral-100">
            {booking.outStation ? (
              <p className="text-sm my-2">
                You are about to book a vehicle for <strong>outstation</strong>{" "}
                purpose. Please note that the vehicle will be allowed to go out
                of city.
              </p>
            ) : (
              <p className="text-sm my-2">
                You are about to book a vehicle for <strong>instation</strong>{" "}
                purpose. Please note that the vehicle will not be allowed to go
                out of city.
              </p>
            )}
            {
              <p className="text-sm my-2">
                If you wish to change the booking type, you can do so by
                clicking on the switch.
              </p>
            }
            {vehicleData.extraKMCharges ? (
              <p className="text-sm mb-5">
                Please note that you will be charged{" "}
                <strong>
                  extra price per km of ₹ {vehicleData.extraKMCharges || 5}
                </strong>{" "}
                after crossing the{" "}
                {Math.round(
                  ((booking.outStation
                    ? vehicleData.KMLimitPerHourOutStation
                    : vehicleData.KMLimitPerHour) || 5) *
                  (Math.ceil(rentDuration / 24) * 24)
                )}{" "}
                km limit.
              </p>
            ) : null}
            <div className="flex justify-between">
              <span>
                ₹{" "}
                {(booking.outStation
                  ? vehicleData.outStationPrice
                  : vehicleData.price) * 24}{" "}
                x {Math.ceil(rentDuration / 24)} day(s)
              </span>
              <span>₹ {rentPrice}</span>
            </div>
            <div className="flex justify-between">
              <span>Addons & Other Charges </span>
              <span>₹ {addonsPrice}</span>
            </div>
            <div className="border-b border-neutral-200 dark:border-neutral-700 my-2"></div>
            <div className="flex justify-between font-semibold">
              <span>Total </span>
              <span>&nbsp;₹ {rentPrice + addonsPrice}</span>
            </div>
            <div className="flex items-center space-x-2 mt-2">
              <Checkbox
                checked={tcAgree}
                onChange={(e) => setTcAgree(e.target.checked)}
              />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                I agree to the{" "}
                <a href="/tc" className="text-primary-500">
                  terms and conditions
                </a>
              </span>
            </div>
          </div>
          <div className="flex flex-col mt-5">
            <ButtonPrimary
              disabled={!tcAgree}
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={() => {
                const bookingData = {
                  outStation: booking.outStation,
                  vehicleData,
                  rentDuration,
                  rentPrice,
                  addonsPrice,
                  dateRangeValue,
                  timeRangeValue,
                };
                setBooking(bookingData);
                if (!user.user) {
                  history.push("/login");
                  return;
                }
                history.push("/checkout");
              }}
            >
              Book
            </ButtonPrimary>
          </div>
        </Modal>
        {VehicleStatus !== "available" && (
          <div style={{ whiteSpace: 'pre-line' }} className="flex items-center space-x-2 mt-2 text-red-500">{slots}</div>
        )}
        <ButtonPrimary
          onClick={() => {
            setOpen(true);
          }}
          disabled={VehicleStatus !== "available"}
        >
          {VehicleStatus === "available"
            ? "Book Now"
            : "Not Available in selected timeframe"}
        </ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage mt-4 nc-ListingCarDetailPage ${className}`}
      data-nc-id="ListingCarDetailPage"
    >
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-4 lg:pr-10 lg:space-y-8">
          <header className="h-auto sm:h-56 lg:h-64 2xl:px-0 rounded-md sm:rounded-xl">
            <div className="relative h-full grid grid-cols-4 gap-1 sm:gap-2">
              <div
                className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={vehicleData.images[0]}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>

              <div
                className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(1)}
              >
                <NcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={vehicleData.images[1]}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>

              {vehicleData.images
                .filter((_: any, i: any) => i >= 2 && i < 4)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 2 ? "block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-5"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={item || ""}
                    />

                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 2)}
                    />
                  </div>
                ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={vehicleData.images}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-ListingCarDetailPage__modalPhotos"
          />
          {renderSection1_mod()}
          {renderSection1()}
          {renderSectionTienIch()}
          {renderSection2()}
          {renderSection3()}
          {renderSectionCheckIndate()}
          {renderSection8()}
        </div>

        <div className="block flex-grow mt-10 lg:mt-0">
          <div className="hidden lg:block top-28">{renderSidebarPrice()}</div>
        </div>
      </main>

      <MobileFooterSticky
        rentPrice={rentPrice}
        originalPrice={originalPrice}
        vehicleData={vehicleData}
        addonsPrice={addonsPrice}
        rentDuration={rentDuration}
      />
    </div>
  );
};

const TimeSelect = ({ value, onChange }: any) => {
  const times = [
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
  ];
  return (
    <div className="px-3 w-full">
      <Listbox
        value={value}
        onChange={onChange}
        as="div"
        className="relative flex flex-row justify-between"
      >
        <Listbox.Button className="focus:outline-none inline-flex items-center group w-full flex-row justify-between">
          <span className="text-base font-semibold">{value}</span>
          <span className="ml-1 z-20 left-full top-0 text-neutral-400 group-hover:text-neutral-900 dark:group-hover:text-neutral-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </span>
        </Listbox.Button>

        <Listbox.Options className="absolute z-50 min-w-max py-1 mt-5 overflow-auto text-base bg-white dark:bg-neutral-800 rounded-md shadow-lg max-h-60 ring-1 ring-black/5 dark:ring-white/20 focus:outline-none sm:text-sm">
          {times.map((time, index) => (
            <Listbox.Option
              key={index}
              className={({ active }) =>
                `${active
                  ? "text-amber-900 bg-amber-100"
                  : "text-gray-900 dark:text-neutral-200"
                } cursor-default select-none relative py-2 pl-10 pr-4`
              }
              value={time}
            >
              {({ selected, active }) => (
                <>
                  <span
                    className={`${selected ? "font-medium" : "font-normal"
                      } block truncate`}
                  >
                    {time}
                  </span>
                  {selected ? (
                    <span
                      className={`${active ? "text-amber-600" : "text-amber-600"
                        }  absolute inset-y-0 left-0 flex items-center pl-3`}
                    >
                      <CheckIcon className="w-5 h-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default ListingCarDetailPage;
