import { Modal, Popconfirm, Switch, Tour } from "antd";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import ModalSelectDate from "components/ModalSelectDate";
import { useBookingData } from "contexts/BookingContext";
import { useDateContext } from "contexts/DateContext";
import { UserContext } from "contexts/UserContext";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalReserveMobile from "./ModalReserveMobile";
import isInViewport from "utils/isInViewport";
import axios from "../../services/api";
let WIN_PREV_POSITION = window.pageYOffset;

const MobileFooterSticky = ({ rentPrice, originalPrice, vehicleData, rentDuration, addonsPrice }: any) => {
  const { booking, setBooking } = useBookingData();
  const ref1 = useRef<HTMLButtonElement>(null);
  let { id }: any = useParams();
  const [VehicleStatus, setVehicleStatus] = useState<any>(false);
  const history = useHistory();
  const { dateRangeValue, setDateRangeValue, timeRangeValue, setTimeRangeValue }: any = useDateContext();
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment().add(4, "days"),
    endDate: moment().add(10, "days"),
  });
  const [slots, setSlots] = useState<any>([]);
  const user: any = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [guestsState, setGuestsState] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  });


  const containerRef = useRef<HTMLDivElement>(null);
  //

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
  }, []);
  const parseTime = (
    timeString: string
  ): { hours: number; minutes: number } => {
    const [time, meridian] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);
    const adjustedHours =
      meridian === "PM" && hours !== 12
        ? hours + 12
        : meridian === "AM" && hours === 12
          ? 0
          : hours;
    return { hours: adjustedHours, minutes };
  };
  function formatDateToISOString(date: Date) {
    // Get the year, month, day, hours, and minutes
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Return the formatted date string
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  useEffect(() => {
    const start = new Date(dateRangeValue.startDate._d);
    const end = new Date(dateRangeValue.endDate._d);

    // Parse timeRangeValue's startTime and endTime
    const { hours: startHours, minutes: startMinutes } = parseTime(
      timeRangeValue.startTime
    );
    const { hours: endHours, minutes: endMinutes } = parseTime(
      timeRangeValue.endTime
    );

    // Set start and end dates with values from timeRangeValue
    const startDate = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      startHours,
      startMinutes,
      0
    );
    const endDate = new Date(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      endHours,
      endMinutes,
      0
    );

    // Add buffer to start and end dates
    const startDateWithBuffer = new Date(
      startDate.getTime() - 2 * 60 * 60 * 1000
    ); // 2 hours earlier
    const endDateWithBuffer = new Date(endDate.getTime() + 2 * 60 * 60 * 1000); // 2 hours later

    // Fetch vehicle status
    axios
      .get(
        `/vehicle-status/${id}?startDate=${formatDateToISOString(
          startDateWithBuffer
        )}&endDate=${formatDateToISOString(endDateWithBuffer)}`
      )
      .then((res) => {
        setVehicleStatus(res.data.status);
        setSlots(res.data.bookingString);
      });
  }, [id, dateRangeValue, timeRangeValue, setVehicleStatus]);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    // SHOW _ HIDE MAIN MENU
    if (document.querySelectorAll(".FooterNav--hide").length > 0) {
      containerRef.current.classList.remove("pb-16");
    } else {
      containerRef.current.classList.add("pb-16");
    }

    // if (currentScrollPos > WIN_PREV_POSITION) {
    //   if (
    //     isInViewport(containerRef.current) &&
    //     currentScrollPos - WIN_PREV_POSITION < 80
    //   ) {
    //     return;
    //   }

    //   containerRef.current.classList.add("pb-16");
    // } else {
    //   if (
    //     !isInViewport(containerRef.current) &&
    //     WIN_PREV_POSITION - currentScrollPos < 80
    //   ) {
    //     return;
    //   }
    //   containerRef.current.classList.remove("pb-16");
    // }

    WIN_PREV_POSITION = currentScrollPos;
  };
  return (
    <>
      <div ref={containerRef} className="block lg:hidden fixed pb-16 bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-20">
        <div className="container flex items-center justify-between">
          <div className="flex flex-row items-center">
            {
              (vehicleData.outStationPrice || vehicleData.outStationPrice != 0) &&
              <div className="mx-2" >
                <Switch className={
                  !booking.outStation ? " bg-slate-300" : ""
                } onChange={
                  () => {
                    setBooking({
                      ...booking,
                      outStation: !booking.outStation
                    });
                  }
                } />
                <p className=" text-xs">{booking.outStation ? "Out Station" : "In Station"}</p>
              </div>
            }

            <div className="">
              <span className="block text-xl font-semibold">
                ₹ {(!booking.outStation ? vehicleData.price : vehicleData.outStationPrice) * 24}
                <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                  / day
                </span>

              </span>
              {/* SUM */}
              <div className="flex flex-col ">
                {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>₹ {vehicleData.price} x {rentDuration} hours</span>
                <span>₹ {rentPrice}</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Addons & Other Charges </span>
                <span>₹ {addonsPrice}</span>
              </div> */}

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold">
                  <span>Total </span>
                  <span className="ml-2"> {rentPrice === originalPrice? `₹ ${rentPrice}`: <span><s className="mr-2">₹{originalPrice}</s>₹{rentPrice}</span>} /-</span>
                </div>
              </div>
              {/* <ModalSelectDate
            defaultValue={selectedDate}
            onSelectDate={setSelectedDate}
            renderChildren={({ defaultValue, openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {converSelectedDateToString(selectedDate)}
              </span>
            )}
          /> */}
            </div>
          </div>
          <ModalReserveMobile
            defaultGuests={guestsState}
            defaultDate={selectedDate}
            onChangeDate={setSelectedDate}
            onChangeGuests={setGuestsState}
            renderChildren={({ openModal }) => (
              <>
                <Modal className="dark:booking-confirmation" open={open} onCancel={() => setOpen(false)} footer={null} title={`Confirmation ${booking.outStation ? 'Out Station' : 'In Station'} booking`}>
                  {VehicleStatus !== "available" ? (
                    <div style={{ whiteSpace: 'pre-line' }} className="flex items-center space-x-2 mt-2 text-red-500">{slots}</div>
                  ) :
                    (<div className="flex flex-col text-neutral-900 dark:text-neutral-100">
                      {
                        // generate paragraph for user confirmation of outstation booking i.e. vehicle will be allowed to go out of city
                        booking.outStation ?
                          <p className="text-sm  font-semibold my-2">
                            You are about to book a vehicle for outstation purpose. Please note that the vehicle will be allowed to go out of city.
                          </p>
                          :
                          <p className="text-sm  font-semibold my-2">
                            You are about to book a vehicle for instation purpose. Please note that the vehicle will not be allowed to go out of city.
                          </p>

                      }
                      {
                        // if you wish to change the booking type, you can do so by clicking on the switch
                        <p className="text-sm  font-semibold my-2">
                          If you wish to change the booking type, you can do so by clicking on the switch.
                        </p>
                      }
                      {
                        // please note that you will be charged extra price per km of vehicleData.extraKMCharges after crossing the vehicleData.KMLimitPerHour * (Math.ceil(rentDuration / 24) *24)
                        vehicleData.extraKMCharges &&
                        <p className="text-sm  font-semibold mb-5">
                          Please note that you will be charged extra price per km of ₹ {vehicleData.extraKMCharges || 5} after crossing the {((booking.outStation ? vehicleData.KMLimitPerHourOutStation : vehicleData.KMLimitPerHour) || 5) * (Math.ceil(rentDuration / 24) * 24)} km limit.
                        </p>

                      }
                      <div className="flex justify-between">
                        <span>₹ {(booking.outStation ? vehicleData.outStationPrice : vehicleData.price) * 24} x {Math.ceil(rentDuration / 24)} day(s)</span>
                        <span>₹ {rentPrice}</span>
                      </div>
                      <div className="flex justify-between">
                        <span>Addons & Other Charges </span>
                        <span>₹ {addonsPrice}</span>
                      </div>
                      <div className="border-b border-neutral-200 dark:border-neutral-700 my-2"></div>
                      <div className="flex justify-between font-semibold">
                        <span>Total </span>
                        <span>&nbsp;₹ {rentPrice + addonsPrice}</span>
                      </div>
                    </div>)

                  }

                  <div className="flex flex-col mt-5">
                    <ButtonPrimary
                      ref={ref1}
                      disabled={VehicleStatus !== "available"}
                      sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                      onClick={() => {

                        const bookingData = {
                          outStation: booking.outStation,
                          vehicleData,
                          rentDuration,
                          rentPrice,
                          addonsPrice,
                          dateRangeValue,
                          timeRangeValue,
                        };
                        setBooking(bookingData);
                        if (!user.user) {
                          history.push('/login');
                          return;
                        }
                        history.push('/checkout');
                        openModal();
                      }}
                    >
                     { VehicleStatus !== "available" ? "Not Available" : "Book"}
                    </ButtonPrimary>
                  </div>

                </Modal>


                <ButtonPrimary
                  ref={ref1}

                  sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"

                  onClick={() => {
                    // const bookingData = {
                    //   vehicleData,
                    //   rentDuration,
                    //   rentPrice,
                    //   addonsPrice,
                    //   dateRangeValue,
                    //   timeRangeValue,
                    // };
                    // setBooking(bookingData);
                    // history.push('/checkout');
                    // openModal();
                    setOpen(true);
                  }}
                >
                  Book
                </ButtonPrimary>
              </>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default MobileFooterSticky;
