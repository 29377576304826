import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import BookingsPage from "containers/BookingsPage/BookingsPage";
import PageOffers from "containers/OffersPage/PageOffers";
import TermsConditions from "containers/InfoPages/TermsConditions";
import PrivacyPolicy from "containers/InfoPages/PrivacyPolicy";
import RefundPolicy from "containers/InfoPages/RefundPolicy";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";

// Page type definition
interface PageType {
  path: string;
  exact?: boolean;
  component: React.ComponentType<any>;
}

// Define all routes
const pages: PageType[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  { path: "/home-3", component: PageHome3 },
  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },
  { path: "/offers", component: PageOffers },
  { path: "/tc", component: TermsConditions },
  { path: "/pp", component: PrivacyPolicy },
  { path: "/rp", component: RefundPolicy },
  { path: "/listing-experiences", component: ListingExperiencesPage },
  { path: "/listing-experiences-map", component: ListingExperiencesMapPage },
  { path: "/listing-experiences-detail", component: ListingExperiencesDetailPage },
  { path: "/listing/:id", component: ListingCarDetailPage },
  { path: "/listing", component: ListingCarPage },
  { path: "/listing-map", component: ListingCarMapPage },
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/listing-real-estate", component: ListingRealEstatePage },
  { path: "/listing-flights", component: ListingFlightsPage },
  { path: "/checkout", component: CheckOutPage },
  { path: "/bookings/:id", component: PayPage },
  { path: "/account", component: AuthorPage },
  { path: "/bookings", component: BookingsPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription }
];

// Cookie Consent Component
const CookieConsent: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem('cookieConsent');
    if (hasConsent === null) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    ReactPixel.grantConsent();
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
    window.location.reload();
  };

  const declineCookies = () => {
    ReactPixel.revokeConsent();
    localStorage.setItem('cookieConsent', 'false');
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-900 text-white p-4 z-50">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center gap-4">
        <p className="text-center md:text-left">
          We use cookies to improve your experience and analyze site usage. 
          By continuing to browse, you agree to our use of cookies.
        </p>
        <div className="flex gap-4">
          <button
            onClick={acceptCookies}
            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded-lg"
          >
            Accept
          </button>
          <button
            onClick={declineCookies}
            className="bg-gray-600 hover:bg-gray-700 px-6 py-2 rounded-lg"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

// Meta Pixel Tracking Component
const MetaPixelTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.init(`${process.env.REACT_APP_META_PIXEL_ID}`);

    // Check consent status
    const hasConsent = localStorage.getItem("cookieConsent") === "true";
    if (!hasConsent) {
      ReactPixel.revokeConsent();
    }
  }, []);

  useEffect(() => {
    const hasConsent = localStorage.getItem("cookieConsent") === "true";
    if (!hasConsent) return;

    const pathname = location.pathname;
    const timestamp = new Date().toISOString();

    // Track all pages as 'PageEvent'
    ReactPixel.pageView();
    ReactPixel.trackCustom("PageEvent", {
      path: pathname,
      timestamp,
    });
  }, [location]);

  return null;
};

// Main Routes Component
const Routes: React.FC = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  
  return (
    <BrowserRouter>
      <ScrollToTop />
      <MetaPixelTracker />
      <CookieConsent />
      <SiteHeader />

      <Switch>
        {pages.map(({ component: Component, path, exact }, ind) => {
          return (
            <Route
              key={`${path}_${ind}`}
              render={(props) => <Component {...props} />}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;