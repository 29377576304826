import {
    ClockCircleOutlined,
    EnvironmentOutlined,
    RightOutlined,
    SafetyCertificateOutlined,
    StarFilled,
    WalletOutlined
} from '@ant-design/icons';
import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

const advantages = [
    {
        title: "Premium Fleet",
        description: "Access to high-end bikes from top brands, regularly maintained for peak performance.",
        icon: <EnvironmentOutlined className="text-4xl" />,
        color: "bg-gradient-to-br from-purple-500 to-indigo-600"
    },
    {
        title: "Flexible Rentals",
        description: "Hourly, daily, or monthly plans with seamless booking and instant confirmation.",
        icon: <ClockCircleOutlined className="text-4xl" />,
        color: "bg-gradient-to-br from-blue-500 to-cyan-600"
    },
    {
        title: "Full Coverage",
        description: "Comprehensive insurance and 24/7 roadside assistance for worry-free rides.",
        icon: <SafetyCertificateOutlined className="text-4xl" />,
        color: "bg-gradient-to-br from-emerald-500 to-teal-600"
    },
    {
        title: "Smart Pricing",
        description: "Transparent rates with special discounts for long-term rentals and memberships.",
        icon: <WalletOutlined className="text-4xl" />,
        color: "bg-gradient-to-br from-orange-500 to-red-600"
    }
];

const testimonials = [
    {
        name: "Ravi Shankar P.",
        role: "Daily Commuter",
        comment: "Start Rides transformed my daily commute. Their electric bikes are amazing!",
        rating: 5
    },
    {
        name: "Mukhesh K.",
        role: "Weekend Explorer",
        comment: "The mountain bikes are top-notch. Perfect for weekend adventures.",
        rating: 5
    },
    {
        name: "Rajesh D.",
        role: "Fitness Enthusiast",
        comment: "Great road bikes and excellent service. Highly recommended!",
        rating: 4
    }
];

const locations = [
    {
        city: "Downtown Hub",
        address: "123 Main Street",
        hours: "7AM - 9PM Daily"
    },
    {
        city: "Beach Location",
        address: "456 Coast Road",
        hours: "8AM - 8PM Daily"
    },
    {
        city: "University Center",
        address: "789 Campus Drive",
        hours: "6AM - 11PM Daily"
    }
];

const PageOffers: React.FC = () => {
    const history = useHistory();

    return (
        <div className="min-h-screen bg-gradient-to-b from-neutral-900 to-neutral-800">
            <Helmet>
                <title>Start Rides - Premium Bike Rentals</title>
            </Helmet>

            {/* Hero Section */}
            <div className="relative h-[70vh] overflow-hidden">
                <div className="absolute inset-0 bg-black/50 z-10" />
                <img
                    src="https://www.carandbike.com/_next/image?url=https%3A%2F%2Fi.ndtvimg.com%2Fi%2F2017-07%2Findian-scout-bobber_827x510_81500190668.jpg&w=750&q=75"
                    alt="Hero bike"
                    className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="relative z-20 container mx-auto h-full flex items-center px-6 lg:px-16">
                    <div className="max-w-2xl">
                        <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
                            Ride in <span className="text-red-500">Style</span>
                        </h1>
                        <p className="text-xl text-neutral-200 mb-8">
                            Experience premium bikes with flexible rental plans designed for urban adventurers and weekend warriors.
                        </p>
                        <button
                            onClick={() => history.push('/')}
                            className="bg-red-500 hover:bg-red-600 text-white px-8 py-4 rounded-full font-semibold flex items-center group transition-all"
                        >
                            Start Your Journey
                            <RightOutlined className="ml-2 group-hover:translate-x-1 transition-transform" />
                        </button>
                    </div>
                </div>
            </div>

            {/* Advantages Grid */}
            <section className="container mx-auto py-20 px-6 lg:px-16">
                <h2 className="text-3xl font-bold text-white text-center mb-16">Why Choose Start Rides</h2>
                <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
                    {advantages.map((advantage, index) => (
                        <div
                            key={index}
                            className="relative overflow-hidden rounded-2xl"
                        >
                            <div className={`${advantage.color} p-8 h-full`}>
                                <div className="text-white mb-4">
                                    {advantage.icon}
                                </div>
                                <h3 className="text-xl font-semibold text-white mb-3">
                                    {advantage.title}
                                </h3>
                                <p className="text-neutral-100">
                                    {advantage.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Testimonials */}
            <section className="bg-neutral-900 py-20">
                <div className="container mx-auto px-6 lg:px-16">
                    <h2 className="text-3xl font-bold text-white text-center mb-16">What Our Riders Say</h2>
                    <div className="grid gap-8 md:grid-cols-3">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="bg-neutral-800 p-6 rounded-xl">
                                <div className="flex gap-1 mb-4">
                                    {[...Array(testimonial.rating)].map((_, i) => (
                                        <StarFilled key={i} className="text-yellow-500" />
                                    ))}
                                </div>
                                <p className="text-neutral-300 mb-4">{testimonial.comment}</p>
                                <div>
                                    <p className="text-white font-semibold">{testimonial.name}</p>
                                    <p className="text-neutral-400 text-sm">{testimonial.role}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Locations */}
            <section className="container mx-auto py-20 px-6 lg:px-16">
                <h2 className="text-3xl font-bold text-white text-center mb-16">Find Us Near You</h2>
                <div className="grid gap-8 md:grid-cols-3">
                    {locations.map((location, index) => (
                        <div key={index} className="bg-neutral-800 p-6 rounded-xl hover:bg-neutral-700 transition-colors">
                            <EnvironmentOutlined className="text-3xl text-red-500 mb-4" />
                            <h3 className="text-xl font-semibold text-white mb-2">{location.city}</h3>
                            <p className="text-neutral-400 mb-2">{location.address}</p>
                            <p className="text-neutral-300 text-sm">{location.hours}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* CTA Section */}
            <section className="bg-gradient-to-r from-gray-500 to-orange-600 py-20">
                <div className="container mx-auto px-6 lg:px-16 text-center">
                    <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                        Ready to Start Your Adventure?
                    </h2>
                    <p className="text-white/90 mb-8 max-w-2xl mx-auto">
                        Join thousands of satisfied riders who've discovered the freedom of premium bike rentals.
                    </p>
                    <button
                        onClick={() => history.push('/')}
                        className="bg-white text-red-500 px-8 py-4 rounded-full font-semibold hover:bg-neutral-100 transition-colors"
                    >
                        Book Your Ride Now
                    </button>
                </div>
            </section>
        </div>
    );
};

export default PageOffers;